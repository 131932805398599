<template>
  <div class="full_bg bg_darkest">
    <div class="row m-0 h-100" :class="isMobile? 'p-0' : 'p-lg-1 p-1'">
      <div class="col-3 p-0 h-100" v-show="!isMobile">
        <div class="row m-0 p-0">
          <div class="col-12 pb-5 text-center" style="position: fixed; bottom: 10px; width: 25%;">
            <img src="../assets/images/more_logo.png" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="p-0 align-self-center" :class="isMobile ? 'col-12' : 'col-9'">
        <profile-details background="light" :personal=true></profile-details>
      </div>
      <div class="col-12 m-0 p-0 p-4 text-right" v-show="isMobile">
        <img src="../assets/images/more_logo.png" style="max-width: 40vw;">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'

export default {
  name: 'profile',
  components: {
    'profile-details': () => import('@/components/Details.vue')
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  }
}
</script>
<style scoped>
</style>
